<template>
  <v-snackbar
    :timeout="0"
    :bottom="true"
    :left="true"
    :value="visible"
  >
    <span v-text="texts.message" />
    <v-btn
      text
      color="pink"
      @click.native="$emit('closed')"
    >
      <span v-text="texts.button" />
    </v-btn>
  </v-snackbar>
</template>

<script>
export default {
  props: {
    visible: {
      type: Boolean,
      required: true
    },
    language: {
      type: String,
      default: 'nl'
    }
  },
  data: () => ({
    messages: {
      nl: {
        message: 'Deze website werkt alleen goed als we cookies kunnen gebruiken.',
        button: 'Ik ga hiermee akkoord'
      },
      en: {
        message: 'This site will only work correctly if we can use cookies.',
        button: 'I allow this'
      }
    }
  }),
  computed: {
    texts () {
      return this.messages[this.language]
    }
  }
}
</script>
