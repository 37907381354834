let fs
let path
if( process.server ) {
  fs = require('fs').promises
  path = require('path')
}

function getSlug (path) {
  return path.substr(0, path.lastIndexOf('.')).replace('./', '')
}

async function processFolder(loc) {
  const pick = require('lodash/pick')
  const contents = {}
  const languages = await fs.readdir(loc)

  for (const lang of languages) {
    contents[lang] = []
    const files = await fs.readdir(path.join(loc, lang))
    for (const file of files) {
      const stats = await fs.stat(path.join(loc, lang, file))
      contents[lang].push({
        slug: getSlug(file),
        ...pick(stats, ['ctime', 'mtime', 'ctimeMs', 'mtimeMs'])
      })
    }
  }
  return contents
}

export const state = () => ({
  sidebar: false,
  cookieWarningVisible: false,
  cookieWarningShown: false,
  content: {
    cases: {},
    blogs: {}
  }
})

export const mutations = {
  toggleSidebar (state) {
    state.sidebar = !state.sidebar
  },
  setContent (state, content) {
    state.content = content
  },
  setCookieWarningVisibility (state, value) {
    state.cookieWarningVisible = value
  },
  setCookieWarningShown (state, value) {
    state.cookieWarningShown = value
  }
}

export const actions = {
  async nuxtServerInit ({ commit, state }) {
    const cases = await processFolder('content/showcase')
    const blogs = await processFolder('content/blog')
    commit('setContent', { cases, blogs })
  },
  showCookieWarning ({ commit, state }) {
    if (state.cookieWarningVisible) return
    commit('setCookieWarningVisibility', true)
  },
  hideCookieWarning ({ commit, state }) {
    if (!state.cookieWarningVisible) return
    commit('setCookieWarningVisibility', false)
    commit('setCookieWarningShown', true)
  }
}
