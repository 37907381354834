import Vue from 'vue'
// Vue.config.optionMergeStrategies.head = Vue.config.optionMergeStrategies.data
import Vuetify from 'vuetify/lib'
import colors from 'vuetify/lib/util/colors'
import '@mdi/font/css/materialdesignicons.css'

Vue.use(Vuetify)

export default ctx => {
  const vuetify = new Vuetify({
    icon: 'mdi',
    theme: {
      themes: {
        light: {
          primary: colors.lightBlue.darken2,
          secondary: colors.green.lighten1,
          shaded: colors.blueGrey.lighten5,
          accent: colors.orange.base
        }
      }
    }
  })

  ctx.app.vuetify = vuetify
  ctx.$vuetify = vuetify.framework
}


