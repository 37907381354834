<template>
  <v-container
    fluid
    class="pb-0"
  >
    <v-row
      class="primary"
    >
      <v-col
        cols="12"
        class="white--text"
        lg="10"
        offset-lg="1"
        xl="8"
        offset-xl="2"
      >
        <v-row>
          <v-col
            cols="12"
            sm="6"
            lg="4"
            order-lg="2"
          >
            <p class="subheading">
              <strong v-text="$t('footer.interested.header')" />
            </p>
            <div
              class="subheading"
              v-text="$t('footer.interested.intro')"
            />
            <v-btn
              class="my-3"
              color="green white--text"
              href="mailto:info@attentionarchitects.nl"
            >
              <v-icon>mdi-email</v-icon>&nbsp;&nbsp;
              <span v-text="$t('footer.interested.button')" />
            </v-btn>
            <div
              class="subheading"
              v-text="$t('footer.interested.intro2')"
            />

          <!-- <v-layout justify-center mt-3>
              <v-btn icon dark large
                v-for="channel in channels"
                :key="channel.url"
                :href="channel.url"
                target="_BLANK">
                <v-icon size="30px">{{ channel.icon }}</v-icon>
              </v-btn>
            </v-layout> -->
          </v-col>

          <v-col
            cols="12"
            sm="6"
            lg="4"
            order-lg="1"
          >
            <div class="subheading">
              <strong>Contact</strong>
            </div>
            <contact-data
              :language="language"
              dark
              color="primary"
            />
          </v-col>

          <v-col
            cols="12"
            lg="4"
            order-lg="3"
          >
            <v-row>
              <v-col cols="12">
                <p class="subheading white--text">
                  <strong v-text="$t('footer.collaborations.intro')" />
                </p>
              </v-col>
              <v-col
                v-for="(collaborator, i) in collaborators"
                :key="i"
                cols="12"
                sm="6"
                md="3"
                lg="6"
              >
                <a
                  :href="collaborator.site"
                  target="_BLANK"
                >
                  <v-img
                    max-height="50px"
                    :src="collaborator.logo"
                    :alt="collaborator.alt"
                    contain
                  />
                </a>
              </v-col>
            </v-row>
          </v-col>
        </v-row>
      </v-col>
      <v-col
        cols="12"
        class="grey lighten-3 text-center"
      >
        <!-- <v-btn text small to="/tos">
          <span v-if="language=='nl'">Algemene voorwaarden</span>
          <span v-if="language=='en'">Terms of service</span>
        </v-btn>
        <span class="hidden-xs-only mr-2">|</span> -->
        &copy;{{ (new Date).getFullYear() }} Attention Architects.&nbsp;
        <span v-text="$t('footer.copyright')" />
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
  import collaborators from '@/assets/json/collaborators.json'
  import ContactData from '@/components/ContactData'

  // import SiteMap from './SiteMap'
  export default {
    components: {
      ContactData
    },
    props: {
      pages: {
        type: Array,
        required: true
      },
      language: {
        type: String,
        default: 'nl'
      }
    },
    data: () => ({
      collaborators,
      channels: [
        {
          icon: 'mdi-facebook',
          url: 'https://www.facebook.com'
        },
        {
          icon: 'mdi-twitter',
          url: 'https://www.twitter.com'
        },
        {
          icon: 'mdi-linkedin',
          url: 'https://www.linkedin.com'
        }
      ]
    })
  }
</script>

<style scoped>
.button-logo {
  max-width: 100%;
  max-height: 40px
}
</style>
