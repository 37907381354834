<template>
  <v-app v-scroll="onScroll">
    <v-navigation-drawer
      v-model="drawer"
      :mini-variant="miniVariant"
      :clipped="clipped"
      disable-resize-watcher
      disable-route-watcher
      fixed
      app
    >
      <v-list>
        <v-list-item
          v-for="(item, i) in pagesWithTitle"
          :key="i"
          router
          exact
          nuxt
          :to="item.to"
          @click="drawer = false"
        >
          <v-list-item-action>
            <v-icon v-html="item.icon" />
          </v-list-item-action>
          <v-list-item-content>
            <v-list-item-title v-text="item.title" />
          </v-list-item-content>
        </v-list-item>
      </v-list>
    </v-navigation-drawer>

    <v-app-bar
      fixed
      app
      :clipped-left="clipped"
      dark
      :flat="transparentToolbar"
      :class="transparentToolbar?'transparent':'primary'"
    >
      <v-app-bar-nav-icon
        class="hidden-md-and-up"
        @click.stop="drawer = !drawer"
      />
      <v-toolbar-title
        :class="{'d-none': transparentToolbar}"
        v-text="title"
      />
      <v-spacer />
      <v-toolbar-items class="hidden-sm-and-down">
        <v-btn
          v-for="(item, i) in pagesWithTitle"
          :key="i"
          :text="!item.color"
          nuxt
          :exact="item.to==='/' || item.to==='/'+$i18n.locale"
          :to="item.to"
          class="px-3"
          :color="item.color"
        >
          {{ item.title }}
        </v-btn>
      </v-toolbar-items>
      <language-switcher :transparent="transparentToolbar" />
    </v-app-bar>

    <v-content>
      <nuxt />
    </v-content>

    <fab-contact />

    <v-footer padless>
      <layout-footer
        :language="language"
        :pages="pages"
      />
    </v-footer>

    <aa-cookie-warning
      :visible="cookieWarningVisible"
      :language="language"
      @closed="closeCookieWarning"
    />
  </v-app>
</template>

<script>
import { mapState } from "vuex";
import FabContact from "../components/fabContact";
import LanguageSwitcher from "../components/LanguageSwitcher";
import AaCookieWarning from "../components/AaCookieWarning";
import LayoutFooter from "../components/LayoutFooter";

export default {
  components: {
    LanguageSwitcher,
    AaCookieWarning,
    LayoutFooter,
    FabContact
  },
  data() {
    return {
      clipped: true,
      drawer: false,
      fixed: false,
      miniVariant: false,
      title: "Attention Architects",
      scrollTop: 0
    };
  },
  computed: {
    ...mapState(["cookieWarningShown", "cookieWarningVisible"]),
    language() {
      return this.$i18n.locale;
    },
    pages() {
      return [
        {
          icon: "mdi-home",
          title: this.$t("nav.home"),
          to: this.localePath("index")
        },
        {
          icon: "mdi-post-outline",
          title: this.$t("nav.blog"),
          to: this.localePath("blog")
        },
        {
          icon: "mdi-check-circle",
          title: this.$t("nav.services"),
          to: this.localePath("services")
        },
        {
          icon: "mdi-briefcase",
          title: this.$t("nav.showcase"),
          to: this.localePath("showcase")
        },
        {
          icon: "mdi-city",
          title: this.$t("nav.contact"),
          to: this.localePath("contact")
        }
      ];
    },
    pagesWithTitle() {
      return this.pages.filter(page => page.title);
    },
    transparentToolbar() {
      if(this.scrollTop > 125 || !this.$route.path.match(/^\/(?:nl\/?)?$/)) {
        return false
      }
      return true
    }
  },

  mounted() {
    this.scrollTop = window.pageYOffset
    setTimeout(this.checkCookieWarning, 1500);
  },
  methods: {
    checkCookieWarning() {
      if (!this.cookieWarningShown) {
        this.$store.dispatch("showCookieWarning");
      }
    },
    closeCookieWarning() {
      this.$store.dispatch("hideCookieWarning");
    },
    onScroll(e) {
      this.scrollTop = window.pageYOffset
    }
  },
  head () {
    return this.$nuxtI18nSeo()
  },
};
</script>
