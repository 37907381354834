<template>
  <v-speed-dial
    v-model="expanded"
    fixed
    bottom
    right
    :direction="direction"
    open-on-hover
  >
    <template v-slot:activator>
      <v-btn
        v-model="expanded"
        color="accent"
        dark
        fab
      >
        <v-icon v-if="expanded">
          mdi-close
        </v-icon>
        <v-icon v-else>
          mdi-comment-question-outline
        </v-icon>
      </v-btn>
    </template>
    <v-tooltip
      :value="tooltips"
      direction="left"
      left
      eager
      nudge-left="15"
      color="green"
    >
      <template v-slot:activator="{ on }">
        <v-btn
          fab
          dark
          small
          color="green"
          href="tel://+31646258151"
          v-on="{ on }"
        >
          <v-icon>mdi-phone</v-icon>
        </v-btn>
      </template>
      <span>{{ $t('fab.phone') }}</span>
    </v-tooltip>
    <v-tooltip
      :value="tooltips"
      direction="left"
      left
      eager
      nudge-left="15"
      color="indigo"
    >
      <template v-slot:activator="{ on }">
        <v-btn
          fab
          dark
          small
          color="indigo"
          href="mailto:info@attentionarchitects.nl"
          v-on="{ on }"
        >
          <v-icon>mdi-email</v-icon>
        </v-btn>
      </template>
      <span>{{ $t('fab.email') }}</span>
    </v-tooltip>
  </v-speed-dial>
</template>

<script>
export default {
  props: {
    direction: {
      type: String,
      default: 'top'
    }
  },
  data() {
    return {
      expanded: false,
      tooltips: false,
      tooltipsDisabled: false
    }
  },
  watch: {
    expanded (val) {
      val && this.$nextTick(() => {
        this.tooltips = true
        this.tooltipsDisabled = true
      })
    },
  }
}
</script>
