import Vue from 'vue'
import Router from 'vue-router'
import { interopDefault } from './utils'
import scrollBehavior from './router.scrollBehavior.js'

const _14a63878 = () => interopDefault(import('../pages/contact.vue' /* webpackChunkName: "pages/contact" */))
const _258faa7d = () => interopDefault(import('../pages/blog/index.vue' /* webpackChunkName: "pages/blog/index" */))
const _3c269f8e = () => interopDefault(import('../pages/services/index.vue' /* webpackChunkName: "pages/services/index" */))
const _afe6ce70 = () => interopDefault(import('../pages/showcase/index.vue' /* webpackChunkName: "pages/showcase/index" */))
const _616e59b0 = () => interopDefault(import('../pages/tos.vue' /* webpackChunkName: "pages/tos" */))
const _3cc6042a = () => interopDefault(import('../pages/index.vue' /* webpackChunkName: "pages/index" */))
const _4179f262 = () => interopDefault(import('../pages/services/general/consultancy.vue' /* webpackChunkName: "pages/services/general/consultancy" */))
const _3ecd9e01 = () => interopDefault(import('../pages/services/general/literature.vue' /* webpackChunkName: "pages/services/general/literature" */))
const _2ada0796 = () => interopDefault(import('../pages/services/humanfactors/pictograms.vue' /* webpackChunkName: "pages/services/humanfactors/pictograms" */))
const _92af658e = () => interopDefault(import('../pages/services/humanfactors/wayfinding.vue' /* webpackChunkName: "pages/services/humanfactors/wayfinding" */))
const _284407f5 = () => interopDefault(import('../pages/services/neuromarketing/ads.vue' /* webpackChunkName: "pages/services/neuromarketing/ads" */))
const _58294f88 = () => interopDefault(import('../pages/services/neuromarketing/packaging.vue' /* webpackChunkName: "pages/services/neuromarketing/packaging" */))
const _3eb627ac = () => interopDefault(import('../pages/services/neuromarketing/shopper.vue' /* webpackChunkName: "pages/services/neuromarketing/shopper" */))
const _23d90935 = () => interopDefault(import('../pages/blog/_slug.vue' /* webpackChunkName: "pages/blog/_slug" */))
const _b3541100 = () => interopDefault(import('../pages/showcase/_slug.vue' /* webpackChunkName: "pages/showcase/_slug" */))

// TODO: remove in Nuxt 3
const emptyFn = () => {}
const originalPush = Router.prototype.push
Router.prototype.push = function push (location, onComplete = emptyFn, onAbort) {
  return originalPush.call(this, location, onComplete, onAbort)
}

Vue.use(Router)

export const routerOptions = {
  mode: 'history',
  base: decodeURI('/'),
  linkActiveClass: 'nuxt-link-active',
  linkExactActiveClass: 'nuxt-link-exact-active',
  scrollBehavior,

  routes: [{
    path: "/about-us",
    component: _14a63878,
    name: "contact___en"
  }, {
    path: "/blog",
    component: _258faa7d,
    name: "blog___en"
  }, {
    path: "/services",
    component: _3c269f8e,
    name: "services___en"
  }, {
    path: "/showcase",
    component: _afe6ce70,
    name: "showcase___en"
  }, {
    path: "/tos",
    component: _616e59b0,
    name: "tos___en"
  }, {
    path: "/nl/",
    component: _3cc6042a,
    name: "index___nl"
  }, {
    path: "/nl/blog",
    component: _258faa7d,
    name: "blog___nl"
  }, {
    path: "/nl/cases",
    component: _afe6ce70,
    name: "showcase___nl"
  }, {
    path: "/nl/diensten",
    component: _3c269f8e,
    name: "services___nl"
  }, {
    path: "/nl/over-ons",
    component: _14a63878,
    name: "contact___nl"
  }, {
    path: "/nl/tos",
    component: _616e59b0,
    name: "tos___nl"
  }, {
    path: "/services/general/consultancy",
    component: _4179f262,
    name: "services-general-consultancy___en"
  }, {
    path: "/services/general/literature",
    component: _3ecd9e01,
    name: "services-general-literature___en"
  }, {
    path: "/services/humanfactors/pictograms",
    component: _2ada0796,
    name: "services-humanfactors-pictograms___en"
  }, {
    path: "/services/humanfactors/wayfinding",
    component: _92af658e,
    name: "services-humanfactors-wayfinding___en"
  }, {
    path: "/services/neuromarketing/ads",
    component: _284407f5,
    name: "services-neuromarketing-ads___en"
  }, {
    path: "/services/neuromarketing/packaging",
    component: _58294f88,
    name: "services-neuromarketing-packaging___en"
  }, {
    path: "/services/neuromarketing/shopper",
    component: _3eb627ac,
    name: "services-neuromarketing-shopper___en"
  }, {
    path: "/nl/diensten/humanfactors/navigatie",
    component: _92af658e,
    name: "services-humanfactors-wayfinding___nl"
  }, {
    path: "/nl/diensten/humanfactors/pictogramonderzoek",
    component: _2ada0796,
    name: "services-humanfactors-pictograms___nl"
  }, {
    path: "/nl/diensten/neuromarketing/advertentieonderzoek",
    component: _284407f5,
    name: "services-neuromarketing-ads___nl"
  }, {
    path: "/nl/diensten/neuromarketing/verpakkingsonderzoek",
    component: _58294f88,
    name: "services-neuromarketing-packaging___nl"
  }, {
    path: "/nl/diensten/neuromarketing/winkelgedrag",
    component: _3eb627ac,
    name: "services-neuromarketing-shopper___nl"
  }, {
    path: "/nl/services/general/consultancy",
    component: _4179f262,
    name: "services-general-consultancy___nl"
  }, {
    path: "/nl/services/general/literature",
    component: _3ecd9e01,
    name: "services-general-literature___nl"
  }, {
    path: "/nl/blog/:slug",
    component: _23d90935,
    name: "blog-slug___nl"
  }, {
    path: "/nl/cases/:slug",
    component: _b3541100,
    name: "showcase-slug___nl"
  }, {
    path: "/blog/:slug",
    component: _23d90935,
    name: "blog-slug___en"
  }, {
    path: "/showcase/:slug",
    component: _b3541100,
    name: "showcase-slug___en"
  }, {
    path: "/",
    component: _3cc6042a,
    name: "index___en"
  }],

  fallback: false
}

export function createRouter () {
  return new Router(routerOptions)
}
