<template>
  <v-btn-toggle
    v-model="languageIndex"
    class="ml-2"
    mandatory
    :class="transparent?'transparent':'primary'"
  >
    <v-btn
      text
      nuxt
      :to="switchLocalePath('nl')"
    >
      <v-avatar
        tile
        size="20px"
      >
        <img
          src="/img/flagNL.png"
          alt="NL"
        >
      </v-avatar>
    </v-btn>
    <v-btn
      text
      nuxt
      :to="switchLocalePath('en')"
    >
      <v-avatar
        tile
        size="20px"
      >
        <img
          src="/img/flagEN.png"
          alt="EN"
        >
      </v-avatar>
    </v-btn>
  </v-btn-toggle>
</template>

<script>
export default {
  props: {
    transparent: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      languageIndex: this.$i18n.locales.findIndex(loc => loc.code === this.$i18n.locale)
    }
  }
}
</script>