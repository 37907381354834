
export const vueI18n = {"fallbackLocale":"en"}

export const vueI18nLoader = false

export const locales = [{"code":"en","iso":"en-US","name":"English","file":"en-US.js"},{"code":"nl","iso":"nl-NL","name":"Nederlands","file":"nl-NL.js"}]

export const defaultLocale = 'en'

export const routesNameSeparator = '___'

export const defaultLocaleRouteNameSuffix = 'default'

export const strategy = 'prefix_except_default'

export const lazy = true

export const langDir = '/lang/'

export const rootRedirect = null

export const detectBrowserLanguage = {"useCookie":true,"cookieKey":"i18n_redirected","alwaysRedirect":false,"fallbackLocale":null}

export const differentDomains = false

export const forwardedHost = false

export const seo = false

export const baseUrl = ''

export const vuex = {"moduleName":"i18n","syncLocale":false,"syncMessages":false,"syncRouteParams":true}

export const parsePages = false

export const pages = {"index":{"en":"/","nl":"/"},"services/index":{"en":"/services","nl":"/diensten"},"contact":{"nl":"/over-ons","en":"/about-us"},"showcase/index":{"nl":"/cases","en":"/showcase"},"showcase/_slug":{"nl":"/cases/:slug","en":"/showcase/:slug"},"services/neuromarketing/shopper":{"nl":"/diensten/neuromarketing/winkelgedrag"},"services/neuromarketing/ads":{"nl":"/diensten/neuromarketing/advertentieonderzoek"},"services/neuromarketing/packaging":{"nl":"/diensten/neuromarketing/verpakkingsonderzoek"},"services/humanfactors/wayfinding":{"nl":"/diensten/humanfactors/navigatie"},"services/humanfactors/pictograms":{"nl":"/diensten/humanfactors/pictogramonderzoek"}}

export const encodePaths = true

export const beforeLanguageSwitch = () => null

export const onLanguageSwitched = () => null

export const MODULE_NAME = 'nuxt-i18n'

export const LOCALE_CODE_KEY = 'code'

export const LOCALE_ISO_KEY = 'iso'

export const LOCALE_DOMAIN_KEY = 'domain'

export const LOCALE_FILE_KEY = 'file'

export const STRATEGIES = {"PREFIX":"prefix","PREFIX_EXCEPT_DEFAULT":"prefix_except_default","PREFIX_AND_DEFAULT":"prefix_and_default","NO_PREFIX":"no_prefix"}

export const COMPONENT_OPTIONS_KEY = 'nuxtI18n'

export const localeCodes = ["en","nl"]
