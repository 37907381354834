<template>
  <div>
    <v-list
      two-line
      :class="color"
      :dark="dark"
    >
      <v-list-item>
        <v-list-item-action>
          <v-icon>mdi-home</v-icon>
        </v-list-item-action>
        <v-list-item-content>
          <v-list-item-title>Attention Architects</v-list-item-title>
          <v-list-item-subtitle>Van der Boechorststraat 7</v-list-item-subtitle>
          <v-list-item-subtitle>1081 BT Amsterdam</v-list-item-subtitle>
        </v-list-item-content>
      </v-list-item>
    </v-list>
    <v-list
      :dark="dark"
      :class="color"
    >
      <v-list-item href="mailto:info@attentionarchitects.nl">
        <v-list-item-action>
          <v-icon>mdi-email</v-icon>
        </v-list-item-action>
        <v-list-item-content>
          <v-list-item-title>info@attentionarchitects.nl</v-list-item-title>
        </v-list-item-content>
      </v-list-item>
      <v-list-item href="tel://+31646258151">
        <v-list-item-action>
          <v-icon>mdi-phone</v-icon>
        </v-list-item-action>
        <v-list-item-content>
          <v-list-item-title>(+31)6 46 25 81 51</v-list-item-title>
        </v-list-item-content>
      </v-list-item>
      <v-list-item>
        <v-list-item-action>
          KvK
        </v-list-item-action>
        <v-list-item-content>
          <v-list-item-title>71906371</v-list-item-title>
        </v-list-item-content>
      </v-list-item>
    </v-list>
  </div>
</template>

<script>
export default {
  props: {
    language: {
      type: String,
      default: 'nl'
    },
    dark: {
      type: Boolean,
      default: false,
    },
    color: {
      type: String,
      default: ''
    }
  }
}
</script>